<template>
  <div v-loading="fullscreenLoading">
    <div class="card mb-5 mb-xl-10" v-if="formData.productID">
      <div class="card-header border-0 pt-6">
        <div class="d-flex flex-column">
          <div class="d-flex align-items-center mb-2 float-left">
            <span class="text-gray-900 fs-2 fw-bolder me-1">{{ oldProductData.name }}</span>
          </div>
          <div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2 float-left">
            <span v-if="oldProductData.description" class="d-flex align-items-center text-gray-400 me-5 mb-2" v-html="oldProductData.description.length > 500 ? oldProductData.description.substring(0,500)+'...' : oldProductData.description"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header border-0 pt-10">
        <el-form @submit.prevent="submitForm()" :model="formData" :rules="rules" ref="formRef" class="w-100" v-loading="fullscreenLoading">
          <div class="row g-9">
            <div class="col-md-6 fv-row">
              <div class="mb-6">
                <label class="required fs-6 fw-bold mb-2">{{ productConstants.PRODUCT_NAME }}</label>
                <el-form-item prop="name">
                  <el-input size="large" v-model="formData.name" type="text" :placeholder="productConstants.PRODUCT_NAME" />
                </el-form-item>
              </div>
            </div>
            <div class="col-md-6 fv-row">
              <div class="mb-6">
                <label class="required fs-6 fw-bold mb-2">{{ productConstants.PRODUCT_SKU }}</label>
                <el-form-item prop="sku_code">
                  <el-input size="large" v-model="formData.sku_code" type="text" :placeholder="productConstants.PRODUCT_SKU" />
                </el-form-item>
              </div>
            </div>
          </div>
          <div class="row g-9">
            <div class="col-md-6 fv-row">
              <div class="mb-6">
                <label class="fs-6 fw-bold mb-2">{{ generalConstants.DESCRIPTION }}</label>
                <el-form-item prop="description">
                  <el-input size="large" v-model="formData.description" :rows="4" type="textarea" :placeholder="generalConstants.DESCRIPTION" />
                </el-form-item>
              </div>
            </div>
          </div>
          <div class="row g-9">
            <div class="col-md-4 fv-row">
              <div class="mb-6">
                <label class="fs-6 fw-bold mb-2">{{ generalConstants.SORTORDER }}</label>
                <el-form-item prop="sort_order">
                  <el-input-number v-model="formData.sort_order" size="large" controls-position="right" />
                </el-form-item>
              </div>
            </div>
            <div class="col-md-4 fv-row">
              <div class="mb-6">
                <label class="fs-6 fw-bold mb-2">{{ generalConstants.STATUS }}</label>
                <el-form-item prop="status">
                  <el-switch v-model="formData.status" class="ml-2" active-color="#13ce66" inactive-color="#ff4949" />
                </el-form-item>
              </div>
            </div>
          </div>

          <div class="demo-collapse">
            <el-collapse v-model="activeNames">
              <el-collapse-item :title="productConstants.PRODUCT_SIZES" name="1">
                <div class="row g-9">
                  <div class="col-md-12 fv-row">
                    <table class="table table-row-dashed fs-6 datatable border gy-2 gs-2">
                      <thead>
                        <tr class="text-start text-gray-800 fw-bolder fs-7 text-uppercase gs-0 text-center">
                          <th class="required">{{ generalConstants.TITLE }}</th>
                          <th class="required" width="150">{{ generalConstants.HEIGHT }}</th>
                          <th class="required" width="150">{{ generalConstants.WIDTH }}</th>
                          <th class="required">
                            {{ productConstants.SIZE_SKU }}
                            <el-tooltip class="box-item" effect="dark" :content="productConstants.SIZE_SKU_HELP" placement="right" raw-content>
                              <i class="bi bi-question-circle-fill"></i>
                            </el-tooltip>
                          </th>
                          <th width="200">{{ generalConstants.SORTORDER }}</th>
                          <th>{{ generalConstants.DEFAULT }}</th>
                          <th>{{ generalConstants.STATUS }}</th>
                          <th>{{ generalConstants.ACTIONS }}</th>
                        </tr>
                      </thead>
                      <tbody class="fw-bold text-gray-600">
                        <tr v-for="(sizes, index) in formData.sizeData" :key="index" v-bind:id="'row_'+index" align="center">
                          <td>
                            <el-form-item :prop="'sizeData.' + index + '.size'" :rules="{
                              required: true,
                              message: productConstants.TITLE_REQUIRED,
                              trigger: 'change',
                            }">
                              <el-input size="large" v-model="sizes.size" :placeholder="generalConstants.TITLE" maxlength="20" :tabindex="index" />
                            </el-form-item>
                            <el-form-item class="d-none">
                              <el-input size="large" type="hidden" v-model="sizes.size_id" />
                            </el-form-item>
                          </td>
                          <td>
                            <el-form-item :prop="'sizeData.' + index + '.height'" :rules="{
                              required: true,
                              message: productConstants.HEIGHT_REQUIRED,
                              trigger: 'change',
                            }">
                              <el-input size="large" v-model="sizes.height" :placeholder="generalConstants.HEIGHT" maxlength="20" :tabindex="index" />
                            </el-form-item>
                          </td>
                          <td>
                            <el-form-item :prop="'sizeData.' + index + '.width'" :rules="{
                              required: true,
                              message: productConstants.WIDTH_REQUIRED,
                              trigger: 'change',
                            }">
                              <el-input size="large" v-model="sizes.width" :placeholder="generalConstants.WIDTH" maxlength="20" :tabindex="index" />
                            </el-form-item>
                          </td>
                          <td>
                            <el-form-item :prop="'sizeData.' + index + '.sku_code'" :rules="{
                              required: true,
                              validator: skuValidate,
                              requiredmessage: productConstants.SIZE_SKU_REQUIRED,
                              trigger: 'change',
                            }">
                              <el-input size="large" v-model="sizes.sku_code" class="text-capitalize" :placeholder="productConstants.SIZE_SKU" maxlength="20" :tabindex="index" />
                            </el-form-item>
                          </td>
                          <td>
                            <el-form-item :prop="'sizeData.' + index + '.sort_order'">
                              <el-input-number v-model="sizes.sort_order" value="0" controls-position="right" :tabindex="index" size="large" />
                            </el-form-item>
                          </td>
                          <td>
                            <el-form-item :prop="'sizeData.' + index + '.is_default'">
                              <el-radio v-model="sizes.is_default" :label="1" @change="changeSizeDefaultVal($event, index)" :tabindex="index" />
                            </el-form-item>
                          </td>
                          <td>
                            <el-form-item :prop="'sizeData.' + index + '.status'">
                              <el-switch v-model="sizes.status" class="ml-2" active-color="#13ce66" inactive-color="#ff4949" :tabindex="index" />
                            </el-form-item>
                          </td>
                          <td>
                            <el-form-item :key="'actions'+index">
                              <span v-if="sizes.is_default=='1'">-</span>
                              <div v-else>
                                <button type="button" @click.prevent="deleteItem(index)" :tabindex="index" class="btn btn-icon btn-light btn-sm me-2">
                                  <i class="bi bi-trash"></i>
                                </button>
                              </div>
                            </el-form-item>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </el-collapse-item>
              <el-collapse-item title="Product Additional Data" name="2" v-if="tableData1.value">
                <el-form :model="formData1" ref="formRef" class="w-100">
                  <div class="row">
                    <div class="col-md-4 mb-10" v-for="masterOption in tableData1.value" :key="masterOption.master_option_id">
                      <div class="h-100 bg-white p-5 shadow-sm">
                        <div class="row card-title border-bottom pb-3 mb-3 fw-bolder text-primary">
                          <div class="col-md-5 px-0">
                            <h3 class="fs-4">{{ masterOption.option_name }}
                            </h3>
                          </div>
                          <div class="col-md-3">
                            <el-input size="small" v-model="formData1[masterOption.master_option_id]['sku_sort_order']" type="text" placeholder="sequence" />
                          </div>
                          <div class="col-md-3 text-end px-0">
                            <el-switch v-if="masterOption.isAssigned" v-model="masterOption.isStatus" active-color="#13ce66" inactive-color="#ff4949" active-value="1" inactive-value="0" @change="changeStatus($event, productID, masterOption.master_option_id)" />
                          </div>
                        </div>
                        <div class="row align-items-center">
                          <div class="col-md-5">
                            <label class="fw-bold">{{ productConstants.ATTRIBUTE }}</label>
                          </div>
                          <div class="col-md-4">
                            <label class="fw-bold">
                              {{ constants.productSku.MODIFYSKU }}
                              <el-tooltip class="box-item" effect="dark" :content="productConstants.MODIFY_SKU_HELP" placement="right" raw-content>
                                <i class="bi bi-question-circle-fill me-2"></i>
                              </el-tooltip>
                              <i class="bi bi-pencil-square" @click="showSkuCode"></i>
                            </label>
                          </div>
                          <div class="col-md-3">
                            <label class="fw-bold">{{ constants.general.SORTORDER }} </label>
                          </div>
                        </div>
                        <div class="row align-items-center" v-for="masterAttribute in masterOption.option_attributes" :key="masterAttribute.master_attribute_id">
                          <div class="col-md-5">
                            <div class="form-check">
                              <input v-model="formData1[masterOption.master_option_id][masterAttribute.master_attribute_id]['is_checked']" class="form-check-input" type="checkbox" :id="formData1[masterOption.master_option_id][masterAttribute.master_attribute_id]['master_attribute_id']" checked>
                              <label class="form-check-label" :for="formData1[masterOption.master_option_id][masterAttribute.master_attribute_id]['master_attribute_id']">
                                {{ masterAttribute.attribute_name }} <span class="text-danger" v-if="masterAttribute.status == '0' "> (X) </span>
                              </label>
                            </div>

                          </div>
                          <div class="col-md-4" v-if="showSkuLabel">
                            <label :class="'text-black-50 ' + (getKeyCountByValue(formData1[masterOption.master_option_id][masterAttribute.master_attribute_id]['assinged_sku_code']) > 1 ? 'bg-warning text-white px-2 py-1' : '') ">{{ formData1[masterOption.master_option_id][masterAttribute.master_attribute_id]['assinged_sku_code'] }}</label>
                          </div>
                          <div class="col-md-4" v-if="showAssignedSKu">
                            <el-input v-model="formData1[masterOption.master_option_id][masterAttribute.master_attribute_id]['assinged_sku_code']" class="text-capitalize" size="large" />
                          </div>
                          <div class="col-md-3">

                            <el-input-number v-model="formData1[masterOption.master_option_id][masterAttribute.master_attribute_id]['sort_order']" class="my-2 w-100" size="default" controls-position="right" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-form>
              </el-collapse-item>
            </el-collapse>
          </div>
          <div class="row g-9 mt-5">
            <div class="col-md-6 fv-row mb-5">
              <button type="button" class=" btn btn-primary me-2" :disabled="loading" @click="submitForm(formRef, 'save')">{{generalConstants.SAVE}}<span v-if="loading" class="spinner-border spinner-border-sm align-middle ms-2"></span>
              </button>
              <button type="button" class=" btn btn-primary me-2" :disabled="loadingback" @click="submitForm(formRef, 'save_back')">{{generalConstants.SAVEANDBACK}}<span v-if="loadingback" class="spinner-border spinner-border-sm align-middle ms-2"></span></button>
              <button type="button" class=" btn btn-secondary me-2" @click.prevent="$router.push({ name: 'imported-products', params: { id: $route.params.productexcelid } })">{{generalConstants.CANCEL}}</button>
            </div>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted, onUpdated } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { useRoute, useRouter } from "vue-router";
import { notificationFire } from "@/composable/notification.js";
import { skuValidate } from "@/composable/common-validation.js";
import { formatText } from "@/core/helpers/common";
import globalConstant from "@/core/data/globalConstant.js";
import { ElMessageBox } from "element-plus";
import { setCurrentPageActionButtons } from "@/core/helpers/actionButtons";
import useEventBus from "../../composable/useEventBus";

export default {
  name: "add-new-product",
  setup() {
    const { onEvent, emitEvent } = useEventBus();
    const route = useRoute();
    const router = useRouter();
    const formRef = ref(null);
    const loading = ref(false);
    const loadingback = ref(false);
    const disabled = ref(false);
    const tableData1 = reactive({});
    const formData1 = ref({});
    const fullscreenLoading = ref(false);
    const activeNames = ref(["0"]);
    const generalConstants = globalConstant.general;
    const productConstants = globalConstant.product;
    const constants = globalConstant;
    const showSkuLabel = ref(true);
    const showAssignedSKu = ref(false);
    const checkDuplicateSKU = ref([]);
    const oldProductData = reactive({
      name: "",
      description: "",
    });
    const formData = reactive({
      productID: 0,
      name: "",
      description: "",
      sort_order: 0,
      status: true,
      sku_code: "",
      sizeData: [
        {
          size_id: "",
          size: "",
          height: "",
          width: "",
          sort_order: 0,
          is_default: 1,
          status: true,
          sku_code: "",
          deleteConfirm: false,
        },
      ],
    });
    //Get click event of toolbar buttons
    onEvent("actionName", (actionName) => {
      if (actionName == "save") {
        submitForm(formRef, "save", true);
      } else if (actionName == "save_back") {
        submitForm(formRef, "save_back", true);
      } else if (actionName == "cancel") {
        router.push({
          name: "imported-products",
          params: { id: route.params.productexcelid },
        });
      }
    });
    const showSkuCode = () => {
      showAssignedSKu.value = true;
      showSkuLabel.value = false;
    };

    const getKeyCountByValue = (val) => {
      return checkDuplicateSKU.value.filter((x) => x === val).length;
    };

    //Change Additional Option Status
    const changeStatus = (status, productId, masterOptionId) => {
      formData1.value[masterOptionId]["status"] = status;
    };

    const tableData = reactive([
      {
        sizeData: [
          {
            size_id: "",
            size: "",
            height: "",
            width: "",
            sort_order: 0,
            is_default: 0,
            status: true,
            sku_code: "",
            deleteConfirm: false,
          },
        ],
      },
    ]);

    const changeSizeDefaultVal = (val, k) => {
      formData.sizeData.forEach((obj) => {
        obj.is_default = 0;
      });
      formData.sizeData[k].is_default = val;
    };

    const deleteArr = [];
    const deleteItem = (index) => {
      ElMessageBox.confirm(generalConstants.DELETE_MESSAGE, "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
        center: true,
      }).then(() => {
        if (formData.sizeData[index].deleteConfirm == true) {
          formData.sizeData[index].action = "delete";
          deleteArr.push(index);
        } else {
          formData.sizeData.splice(index, 1);
        }
      });
    };

    const rules = reactive({
      name: [
        {
          required: true,
          message: "Product name is required",
          trigger: "change",
        },
      ],
      sku_code: [
        {
          validator: skuValidate,
          required: true,
          requiredmessage: "Product SKU code is required",
          trigger: "change",
        },
      ],
    });

    const submitForm = async (
      formEl,
      btnName,
      isToolbarButtonsSubmitAction = false
    ) => {
      formEl = isToolbarButtonsSubmitAction ? formEl.value : formEl;
      if (!formEl) {
        return;
      }
      formEl.validate((valid) => {
        if (!valid) return false;

        isToolbarButtonsSubmitAction
          ? emitEvent("loader", { save_loader: true, action: btnName })
          : btnName == "save"
          ? (loading.value = true)
          : (loadingback.value = true);

        formData.sizeData.forEach((obj) => {
          obj.size == "" ? (obj.size = null) : obj.size;
          obj.height == "" ? (obj.height = null) : obj.height;
          obj.weight == "" ? (obj.weight = null) : obj.weight;
          obj.sort_order == "" ? (obj.sort_order = 0) : obj.sort_order;
          obj.sku_code == "" ? (obj.sku_code = null) : obj.sku_code;
          obj.is_default == "" ? (obj.is_default = 0) : obj.is_default;
          obj.status == 1 ? (obj.status = true) : (obj.status = false);
          if (formData.productID > 0) {
            if (obj.size_id && obj.action != "delete") {
              obj.action = "edit";
            } else if (!obj.size_id) {
              obj.action = "add";
            }
          }
          delete obj.deleteConfirm;
        });
        let addParams = {
          _method: formData.productID > 0 ? "PUT" : null,
          name: formData.name,
          description: formData.description,
          sort_order: formData.sort_order,
          status: formData.status,
          sku_code: formData.sku_code,
          product_sizes: formData.sizeData,
          additionalData: JSON.stringify(formData1.value),
        };
        let apiURL = "product-import-history";
        if (formData.productID > 0) {
          apiURL = "product-import-history/" + formData.productID;
        }
        ApiService.post(apiURL, addParams)
          .then(({ data }) => {
            if (data) {
              if (formData.productID == 0 || formData.productID === undefined)
                formData.productID = data.data.id;
              oldProductData.name = formData.name;
              oldProductData.description = formData.description;
              notificationFire(data.message, "success");
              setCurrentPageBreadcrumbs(
                "Edit Product (" + formData.name + ")",
                ["Products"]
              );
              if (btnName == "save") {
                isToolbarButtonsSubmitAction
                  ? emitEvent("loader", { save_loader: false, action: btnName })
                  : btnName == "save"
                  ? (loading.value = false)
                  : (loadingback.value = false);

                if (data.data) {
                  let diff = 0;
                  data.data.sizes.forEach((obj) => {
                    formData.sizeData[diff].size_id = obj.size_id;
                    formData.sizeData[diff].deleteConfirm = true;
                    diff++;
                  });
                }

                deleteArr.forEach((obj) => {
                  formData.sizeData.splice(obj, 1);
                });

                router.push({ name: "import-products-history" });
              } else if (btnName == "save_back") {
                router.push({ name: "import-products-history" });
              }
            }
          })
          .catch((error) => {
            isToolbarButtonsSubmitAction
              ? emitEvent("loader", { save_loader: false, action: btnName })
              : btnName == "save"
              ? (loading.value = false)
              : (loadingback.value = false);
            let response = error.response;
            notificationFire(response.data.message, "error");
          });
      });
    };

    const getProductData = async () => {
      await ApiService.query("products/" + formData.productID)
        .then(({ data }) => {
          if (data.data) {
            let productData = data.data;
            formData.name = oldProductData.name = productData.name;
            formData.description = oldProductData.description =
              productData.description;
            formData.sort_order =
              productData.sort_order == null ? 0 : productData.sort_order;
            formData.sku_code = productData.sku_code;
            formData.status = productData.status == 1 ? true : false;
            formData.sizeData = [];
            formData.sizeData = productData.sizes;
            formData.sizeData.forEach((obj) => {
              obj.status = obj.status == 1 ? true : false;
              obj.deleteConfirm = true;
            });
            setCurrentPageBreadcrumbs(
              formatText(route.name) + " (" + formData.name + ")",
              [{"name" :formatText("products"), 'path': "products"}, {"name" :formatText("Import Products History"), 'path': "import-products-history"}, {"name" :formatText("Imported Products"), 'path': "imported-products", 'id': route.params.productexcelid}, {"name" : formatText(route.name), active: true}]
            );
          } else {
            notificationFire("Resource Not Found.", "error");
            router.push({ name: "products" });
          }
        })
        .catch((error) => {
          let response = error.response;
          if (response) {
            let status = response.status;
            let message = response.data.message;
            notificationFire(message, "error");
            if (status == 404) {
              router.push({ name: "products" });
            }
          }
        });
    };

    const getMasterOptionDetails = async () => {
      await ApiService.query("assign-product-options", {
        params: {
          product_id: formData.productID,
        },
      })
        .then((data) => {
          tableData1.value = data.data.data.additionalData;
          checkDuplicateSKU.value = [];
          tableData1.value.forEach((masterOption) => {
            formData1.value[masterOption.master_option_id] = {};
            let optionStatus =
              masterOption.additional_assign_data != null
                ? masterOption.additional_assign_data.status
                : "0";
            formData1.value[masterOption.master_option_id]["status"] =
              optionStatus;
            let assignedOptionId =
              masterOption.additional_assign_data != null
                ? masterOption.additional_assign_data.additional_option_id
                : "";
            let optionSkuSortOrder =
              masterOption.additional_assign_data != null
                ? masterOption.additional_assign_data.sku_sort_order
                : "";
            formData1.value[masterOption.master_option_id]["sku_sort_order"] =
              optionSkuSortOrder;
            let ab = [];
            masterOption.option_attributes.forEach((attr) => {
              let isAttributeAssigned =
                attr.assignedAttribute.length > 0 ? true : false;
              ab.push(isAttributeAssigned);
              formData1.value[masterOption.master_option_id][
                attr.master_attribute_id
              ] = {};
              formData1.value[masterOption.master_option_id][
                attr.master_attribute_id
              ]["master_attribute_id"] = attr.master_attribute_id;
              formData1.value[masterOption.master_option_id][
                attr.master_attribute_id
              ]["master_attribute_id"] = attr.master_attribute_id;
              formData1.value[masterOption.master_option_id][
                attr.master_attribute_id
              ]["master_option_id"] = attr.master_option_id;
              formData1.value[masterOption.master_option_id][
                attr.master_attribute_id
              ]["sku_code"] = attr.sku_code;
              if (attr.assignedAttribute.length > 0) {
                if (
                  attr.assignedAttribute[0].sku_code != "" &&
                  attr.assignedAttribute[0].sku_code != null
                ) {
                  formData1.value[masterOption.master_option_id][
                    attr.master_attribute_id
                  ]["assinged_sku_code"] = attr.assignedAttribute[0].sku_code;
                  checkDuplicateSKU.value.push(
                    attr.assignedAttribute[0].sku_code
                  );
                } else {
                  formData1.value[masterOption.master_option_id][
                    attr.master_attribute_id
                  ]["assinged_sku_code"] = attr.sku_code;
                  checkDuplicateSKU.value.push(attr.sku_code);
                }
                formData1.value[masterOption.master_option_id][
                  attr.master_attribute_id
                ]["sort_order"] = attr.assignedAttribute[0].sort_order;
                formData1.value[masterOption.master_option_id][
                  attr.master_attribute_id
                ]["is_checked"] = true;
              } else {
                checkDuplicateSKU.value.push(attr.sku_code);
                formData1.value[masterOption.master_option_id][
                  attr.master_attribute_id
                ]["assinged_sku_code"] = attr.sku_code;
                formData1.value[masterOption.master_option_id][
                  attr.master_attribute_id
                ]["is_checked"] = false;
                formData1.value[masterOption.master_option_id][
                  attr.master_attribute_id
                ]["sort_order"] = 0;
              }
            });
            formData1.value[masterOption.master_option_id]["status_visible"] =
              ab.some(Boolean) ? 1 : 0;
            masterOption.isAssigned = ab.some(Boolean);
            masterOption.isStatus = String(optionStatus);
            masterOption.additional_option_id = assignedOptionId;
          });
          productName.value = data.data.data.productData.name;
        })
        .catch((error) => {
          let response = error.response;
          if (response) {
            let status = response.status;
            notificationFire(response.data.message, "error");
            if (status == 404) {
              router.push({ path: "/products" });
            }
          }
        });
    };

    //Generate Current Page Toolbar Dynamic Buttons
    function generateHeaderToolbarButtons() {
      return setCurrentPageActionButtons([
        {
          name: generalConstants.SAVE,
          action: "save",
          class: "btn-primary me-3",
          isLoader: false,
        },
        {
          name: generalConstants.SAVEANDBACK,
          action: "save_back",
          class: "btn-primary me-3",
          isLoader: false,
        },
        {
          name: generalConstants.CANCEL,
          action: "cancel",
          class: "btn-secondary",
          isLoader: false,
        },
      ]);
    }

    //Set breadcrumbs
    onMounted(async () => {
      setCurrentPageBreadcrumbs(formatText(route.name), [
        { name: formatText("products"), path: "products" },
        {
          name: formatText("Import Products History"),
          path: "import-products-history",
        },
        { name: formatText(route.name), active: true },
      ]);
      formData.productID = router.currentRoute.value.params.id;
      if (formData.productID > 0) {
        fullscreenLoading.value = true;
        await getProductData();
        await getMasterOptionDetails();
        fullscreenLoading.value = false;
      }
      generateHeaderToolbarButtons();
    });

    onUpdated(async () => {
      generateHeaderToolbarButtons();
    });
    return {
      router,
      formRef,
      loading,
      loadingback,
      formData,
      tableData,
      tableData1,
      formData1,
      rules,
      submitForm,
      getProductData,
      changeSizeDefaultVal,
      deleteItem,
      disabled,
      skuValidate,
      deleteArr,
      fullscreenLoading,
      activeNames,
      generalConstants,
      productConstants,
      constants,
      showAssignedSKu,
      showSkuLabel,
      showSkuCode,
      getKeyCountByValue,
      changeStatus,
      oldProductData,
    };
  },
};
</script>
<style>
.el-radio__label {
  visibility: hidden;
}
.el-form-item {
  margin-bottom: 14px;
}
table .el-form-item__content {
  line-height: 44px;
}
table .el-form-item__content {
  justify-content: center;
}
table .el-form-item__error {
  font-weight: 400;
}
.el-input-number {
  width: 22%;
}
table .el-input-number {
  width: 60%;
}
table .el-form-item {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>